<script>
// import { TimelineMax, TweenMax } from 'gsap'
import Icons from '../../mixins/Icons'

export default {
  name: 'BgRightBlueToPink',
  mixins: [Icons],
  props: { devise: { type: Object, default: () => ({}) } },
  data() {
    return {
      timeline: null,
    }
  },
  mounted() {
    // this.timeline = new TimelineMax({ paused: true })

    // const polygons = this.$refs.poly
    // const paths = this.$refs.path

    // this.timeline
    //   .addLabel('barsAndMask')
    //   .staggerFromTo(
    //     polygons,
    //     3,
    //     {
    //       opacity: 0,
    //       transform: 'translate3d(0,-5px,0)',
    //     },
    //     {
    //       opacity: 1,
    //       transform: 'translate3d(0,0,0)',
    //     },
    //     0.3,
    //     'barsAndMask',
    //   )
    //   .staggerFromTo(
    //     paths,
    //     3,
    //     {
    //       opacity: 0,
    //       transform: 'translate3d(0,-15px,0)',
    //     },
    //     {
    //       opacity: 1,
    //       transform: 'translate3d(0,0,0)',
    //     },
    //     0.3,
    //     'barsAndMask',
    //   )
  },
  methods: {
    onVisibilityChange(entry) {
      if (entry.intersectionRatio * 100 > 0)
        this.timeline.restart()
    },
  },
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    v-voix-visibility="{ callback: onVisibilityChange, threshold: [0, 0.25] }"
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 200 200"
    role="presentation"
  >
    <g id="shoosh-top-right-blue-to-pink">
      <path
        fill="#0D989A"
        d="M200,68.1V74l-32.9-21.4c-5.3-3.5-11.6-7.3-11.9-7.6L85.9,0h9.3L200,68.1z"
      />
      <polygon
        fill="#169292"
        points="200,56.8 200,68.1 95.2,0 112.7,0 	"
      />
      <polygon fill="#1EACAC" points="200,48 200,56.8 112.7,0 126.2,0 	" />
      <polygon fill="#6FCCDD" points="200,0 200,48 126.2,0 	" />
      <path
        fill="#D23477"
        d="M155.2,45c-3.7-1.8-9.9-3.3-16.7-3.3H66.1L24.8,14.8L2,0h83.9L155.2,45z"
      />
    </g>
  </svg>
</template>
